{
  "id": 'sorghum',
  "name": 'Sorghumbase',
  "url": 'https://sorghumbase.org',
  "ensemblURL": 'https://ensembl.sorghumbase.org',
  "ensemblSite": 'https://ensembl.sorghumbase.org',
  "ensemblRest": 'https://data.gramene.org/pansite-ensembl-108',
  "grameneData": 'https://data.sorghumbase.org/sorghum_v9',
  "targetTaxonId": 4558003,
  "ga": 'G-L5KXDCCZ16',
  "curation": {
    "url": "http://curate.gramene.org/sorghum_v6?since=4479&gene=",
    "taxa": {
      "4558": 1,
      "4558003": 1
    }
  },
  details: {
    sequences: true,
    VEP: true,
    location: true,
    expression: true,
    homology: true,
    pathways: true,
    pubs: true,
    xrefs: true
  },
  "panSite" : {
    vitis_vinifera : {
      url: "https://vitis.gramene.org?idList=",
      name: "Gramene Grapevine",
      svg: "./static/images/grapevine_logo.svg"
    },
    oryza_sativa : {
      url: "https://oryza.gramene.org?idList=",
      name: "Gramene Oryza",
      svg: "./static/images/oryza_logo.svg"
    },
    zea_maysb73 : {
      url: "https://maize-pangenome.gramene.org?idList=",
      name: "Gramene Maize",
      svg: "./static/images/maize_logo.svg"
    }
  },
  "alertText": 'Click the search icon in the menu bar or type / to search'
}
